import gql from "graphql-tag";

// PRODUK
export const Q_LIST_PRODUK = gql`
  query listProduk($page: Int, $limit: Int, $keyword: String) {
    listProduk(
      listProdukInput: {
        page: $page
        limit: $limit
        search: { keyword: $keyword }
      }
    ) {
      produks {
        id
        brand
        ingredient
        category {
          id
          category
        }
        detail_produk {
          crops {
            id
            crop
          }
        }
        label
        dosegr
        doseHaKg
        regHolder
        regNum
        price
        volume_per_demoplot
        jumlah_tangki
        kebutuhan_sample
        jumlah_bungkus_botol
        isSample
        isMatpro
      }
      page
      limit
      total
      totalPage
    }
  }
`;
export const M_ADD_PRODUK = gql`
  mutation addProduk(
    $brand: String!
    $ingr: String!
    $category_id: String!
    $crop: [String!]!
    $label: String!
    $dosegr: String
    $doseHaKg: String
    $regHolder: RegistrationHolderType!
    $regNum: String!
    $price: Int
    $dosisPerTangki: Float!
    $jumlahTangki: Int!
    $dosisPerDemo: Float!
    $ukuranKemasanSample: Float!
  ) {
    createProduk(
      createProdukInput: {
        brand: $brand
        ingredient: $ingr
        category_id: $category_id
        crops: $crop
        label: $label
        dosegr: $dosegr
        doseHaKg: $doseHaKg
        regHolder: $regHolder
        regNum: $regNum
        price: $price
        dosisPerTangki: $dosisPerTangki
        jumlahTangki: $jumlahTangki
        dosisPerDemo: $dosisPerDemo
        ukuranKemasanSample: $ukuranKemasanSample
      }
    ) {
      id
    }
  }
`;
export const M_EDIT_PRODUK = gql`
  mutation updateProduk(
    $id: ID!
    $brand: String!
    $ingr: String!
    $category_id: String!
    $crop: [String!]
    $label: String
    $dosegr: String
    $doseHaKg: String
    $regHolder: RegistrationHolderType!
    $regNum: String
    $price: Int
    $dosisPerTangki: Float!
    $jumlahTangki: Int!
    $dosisPerDemo: Float!
    $ukuranKemasanSample: Float!
  ) {
    updateProduk(
      updateProdukInput: {
        id: $id
        brand: $brand
        ingredient: $ingr
        category_id: $category_id
        crops: $crop
        label: $label
        dosegr: $dosegr
        doseHaKg: $doseHaKg
        regHolder: $regHolder
        regNum: $regNum
        price: $price
        dosisPerTangki: $dosisPerTangki
        jumlahTangki: $jumlahTangki
        dosisPerDemo: $dosisPerDemo
        ukuranKemasanSample: $ukuranKemasanSample
      }
    ) {
      id
    }
  }
`;
export const M_DEL_PRODUK = gql`
  mutation deleteProduk($id: ID!) {
    deleteProduk(id: $id) {
      id
      brand
    }
  }
`;

// PETANI
export const Q_LIST_FARMER = gql`
  query listPetani($page: Int, $limit: Int, $keyword: String) {
    listPetani(
      listPetaniInput: {
        page: $page
        limit: $limit
        search: {
          keyword: $keyword
          sp_id: []
          tss_id: []
          prov_id: []
          country_id: []
          region_id: []
          kab_id: []
        }
      }
    ) {
      limit
      page
      total
      totalPage
      petanis {
        id
        name
        sp {
          id
          name
        }
        tss {
          id
          name
        }
        kab_id
        kab {
          id
          kabupaten
        }
        kec {
          id
          kecamatan
        }
        kel {
          id
          kelurahan
        }
        luas
        alamat
        hp
        ucrops {
          id
          crop
        }
        ocrops {
          id
          crop
        }
      }
    }
  }
`;
export const M_ADD_FARMER = gql`
  mutation addPetani(
    $name: String!
    $sp_id: String!
    $tss_id: String!
    $kec_id: String!
    $kel_id: String!
    $alamat: String!
    $hp: String!
    $luas: Float!
    $ucrop_id: String!
    $ocrop_id: String
  ) {
    createPetani(
      createPetaniInput: {
        name: $name
        sp_id: $sp_id
        tss_id: $tss_id
        kec_id: $kec_id
        kel_id: $kel_id
        alamat: $alamat
        hp: $hp
        luas: $luas
        ucrop_id: $ucrop_id
        ocrop_id: $ocrop_id
      }
    ) {
      id
      name
    }
  }
`;
export const M_DEL_FARMER = gql`
  mutation deletePetani($id: ID!) {
    deletePetani(id: $id) {
      id
      name
    }
  }
`;
export const M_EDIT_FARMER = gql`
  mutation editPetani(
    $id: ID!
    $name: String!
    $sp_id: String!
    $tss_id: String!
    $kec_id: String!
    $kel_id: String!
    $alamat: String!
    $hp: String!
    $luas: Float!
    $ucrop_id: String!
    $ocrop_id: String
  ) {
    updatePetani(
      updatePetaniInput: {
        id: $id
        name: $name
        sp_id: $sp_id
        tss_id: $tss_id
        kec_id: $kec_id
        kel_id: $kel_id
        alamat: $alamat
        hp: $hp
        luas: $luas
        ucrop_id: $ucrop_id
        ocrop_id: $ocrop_id
      }
    ) {
      id
      name
    }
  }
`;

// USER
export const Q_LIST_USER = gql`
  query allSP($role: roleType!, $page: Int, $limit: Int, $keyword: String) {
    listUser(
      listUserInput: {
        page: $page
        limit: $limit
        search: {
          keyword: $keyword
          role: $role
          country_id: []
          region_id: []
          province_id: []
          kabupaten_id: []
          kecamatan_id: []
          kelurahan_id: []
          tss_id: []
        }
      }
    ) {
      users {
        id
        name
        role
        detail {
          country_id
          region_id
          province_id
          kabupaten_id
          kecamatan_id
          kelurahan_id
        }
      }
    }
  }
`;
export const DETAIL_USER = gql`
  query detailUser($id: ID!) {
    detailUser(id: $id) {
      id
      ugdn
      name
      email
      phone
      profile
      role
      approvePropos {
        id
        canApprove
        canDeliver
        canProcess
      }
      detail {
        id
        group_id
        country_id
        region_id
        province_id
        kabupaten_id
        kabupaten {
          id
          kabupaten
        }
        group_id
        tss_id
        tss {
          id
          ugdn
          email
          phone
          name
          role
          isActive
        }
      }
    }
  }
`;
export const M_ADD_USER = gql`
  mutation createUser(
    $name: String!
    $email: String!
    $phone: String!
    $ugdn: String!
    $role: roleType!
    $detail: [CreateDetailUserInput!]
  ) {
    createUser(
      createUserInput: {
        name: $name
        email: $email
        phone: $phone
        ugdn: $ugdn
        role: $role
        detail: $detail
      }
    ) {
      id
    }
  }
`;

export const listProvinces = gql`
  query listProvince(
    $page: Int
    $limit: Int
    $keyword: String
    $region_id: ID
  ) {
    listProvince(
      listProvinceInput: {
        page: $page
        limit: $limit
        search: { keyword: $keyword, region_id: $region_id }
      }
    ) {
      page
      limit
      total
      totalPage
      provinces {
        id
        province
        regions {
          id
          region
        }
      }
    }
  }
`;
export const updateProvince = gql`
  mutation updateProvince($id: ID!, $region_id: ID!) {
    updateProvince(updateProvinceInput: { id: $id, region_id: $region_id }) {
      id
    }
  }
`;
export const Q_LIST_PROVINCE = gql`
  query listProvince($keyword: String, $region_id: ID) {
    listProvince(
      listProvinceInput: {
        page: 1
        limit: 999
        search: { keyword: $keyword, region_id: $region_id }
      }
    ) {
      provinces {
        id
        province
        regions {
          id
          region
        }
      }
    }
  }
`;
export const listKabupatens = gql`
  query listKab(
    $page: Int!
    $limit: Int!
    $keyword: String
    $province_id: String!
  ) {
    listKabupaten(
      listKabupatenInput: {
        page: $page
        limit: $limit
        search: { keyword: $keyword, province_id: $province_id }
      }
    ) {
      page
      limit
      total
      totalPage
      kabupatens {
        id
        kabupaten
        provinces {
          id
          province
        }
      }
    }
  }
`;
export const Q_LIST_KABUPATEN = gql`
  query listKab($keyword: String, $province_id: String!) {
    listKabupaten(
      listKabupatenInput: {
        page: 1
        limit: 99
        search: { keyword: $keyword, province_id: $province_id }
      }
    ) {
      kabupatens {
        id
        kabupaten
      }
    }
  }
`;
export const Q_LIST_KECAMATAN = gql`
  query listKecamatan($keyword: String, $kabupaten_id: String!) {
    listKecamatan(
      listKecamatanInput: {
        page: 1
        limit: 25
        search: { keyword: $keyword, kabupaten_id: $kabupaten_id }
      }
    ) {
      kecamatans {
        id
        kecamatan
      }
    }
  }
`;
export const Q_LIST_KELURAHAN = gql`
  query listKelurahan($kecamatan_id: String!) {
    listKelurahan(
      listKelurahanInput: {
        page: 1
        limit: 25
        search: { keyword: "", kecamatan_id: $kecamatan_id }
      }
    ) {
      kelurahans {
        id
        kelurahan
      }
    }
  }
`;

// CROPS
export const Q_LIST_CROP = gql`
  query allTanaman($page: Int, $limit: Int, $keyword: String) {
    listCropName(
      listCropNameInput: {
        page: $page
        limit: $limit
        search: { keyword: $keyword }
      }
    ) {
      page
      total
      totalPage
      crops {
        id
        crop
      }
    }
  }
`;
export const M_ADD_CROP = gql`
  mutation addTanaman($crop: String!) {
    createCropName(createCropNameInput: { crop: $crop }) {
      id
      crop
    }
  }
`;
export const M_EDIT_CROP = gql`
  mutation updateCrop($id: ID!, $crop: String!) {
    updateCropName(updateCropNameInput: { id: $id, crop: $crop }) {
      id
      crop
    }
  }
`;
export const M_DEL_CROP = gql`
  mutation deleteTanaman($id: ID!) {
    deleteCropName(id: $id) {
      id
      crop
    }
  }
`;

// COUNTRIES
export const Q_LIST_COUNTRY = gql`
  query listCountry {
    listCountry(
      listCountryInput: { page: 1, limit: 99, search: { keyword: "" } }
    ) {
      page
      total
      totalPage
      countrys {
        id
        country
      }
    }
  }
`;
export const M_ADD_COUNTRY = gql`
  mutation addCountry($country: String!) {
    createCountry(createCountryInput: { country: $country }) {
      id
      country
    }
  }
`;
export const M_DEL_COUNTRY = gql`
  mutation deleteCountry($id: ID!) {
    deleteCountry(id: $id) {
      id
      country
    }
  }
`;
export const M_EDIT_COUNTRY = gql`
  mutation updateCountry($id: ID!, $country: String!) {
    updateCountry(updateCountryInput: { id: $id, country: $country }) {
      id
      country
    }
  }
`;

// REGIONS
export const Q_LIST_REGION = gql`
  query listRegion($page: Int, $limit: Int, $keyword: String) {
    listRegion(
      listRegionInput: {
        page: $page
        limit: $limit
        search: { keyword: $keyword }
      }
    ) {
      page
      total
      totalPage
      regions {
        id
        region
        countrys {
          id
          country
        }
      }
    }
  }
`;
export const listRegion = gql`
  query listRegion($page: Int, $limit: Int, $keyword: String) {
    listRegion(
      listRegionInput: {
        page: $page
        limit: $limit
        search: { keyword: $keyword }
      }
    ) {
      regions {
        id
        region
      }
    }
  }
`;
export const M_ADD_REGION = gql`
  mutation addRegion($region: String!, $country_id: Int!) {
    createRegion(
      createRegionInput: { region: $region, country_id: $country_id }
    ) {
      id
      region
      country_id
    }
  }
`;
export const M_DEL_REGION = gql`
  mutation delRegion($id: ID!) {
    deleteRegion(id: $id) {
      id
      region
    }
  }
`;
export const M_EDIT_REGION = gql`
  mutation updateRegion($id: ID!, $country_id: Int!, $region: String!) {
    updateRegion(
      updateRegionInput: { id: $id, country_id: $country_id, region: $region }
    ) {
      id
      region
    }
  }
`;

// CATEGORY
export const Q_LIST_CATEGORY = gql`
  query allCategory($page: Int, $limit: Int, $keyword: String) {
    listCategoryProduk(
      listCategoryProdukInput: {
        page: $page
        limit: $limit
        search: { keyword: $keyword }
      }
    ) {
      page
      total
      totalPage
      categories {
        id
        category
      }
    }
  }
`;
export const M_ADD_CATEGORY = gql`
  mutation addCategory($category: String!) {
    createCategoryProduk(createCategoryProdukInput: { category: $category }) {
      id
      category
    }
  }
`;
export const M_DEL_CATEGORY = gql`
  mutation deleteCategory($id: ID!) {
    deleteCategoryProduk(id: $id) {
      id
      category
    }
  }
`;
export const M_EDIT_CATEGORY = gql`
  mutation editCategory($id: ID!, $category: String!) {
    updateCategoryProduk(
      updateCategoryProdukInput: { id: $id, category: $category }
    ) {
      id
      category
    }
  }
`;

export const Q_LIST_GROUP = gql`
  query listGroup {
    listGroup(
      listGroupInput: {
        page: 1
        limit: 10
        search: { keyword: "" }
        order: { orderBy: CREATED_AT, sortBy: ASC }
      }
    ) {
      groups {
        id
        group
      }
    }
  }
`;

// GRUP
export const listGroups = gql`
  query listGroup(
    $page: Int
    $limit: Int
    $keyword: String
    $orderBy: GroupOrderType!
    $sortBy: SortByType!
  ) {
    listGroup(
      listGroupInput: {
        page: $page
        limit: $limit
        search: { keyword: $keyword }
        order: { orderBy: $orderBy, sortBy: $sortBy }
      }
    ) {
      page
      limit
      total
      totalPage
      groups {
        id
        group
      }
    }
  }
`;

export const createGroup = gql`
  mutation createGroup($group: String!) {
    createGroup(createGroupInput: { group: $group }) {
      id
    }
  }
`;

export const updateGroup = gql`
  mutation updateGroup($id: ID!, $group: String!) {
    updateGroup(updateGroupInput: { id: $id, group: $group }) {
      id
    }
  }
`;

export const deleteGroup = gql`
  mutation deleteGroup($id: ID!) {
    deleteGroup(id: $id) {
      id
    }
  }
`;

// RETAILER
export const Q_LIST_RETAILER = gql`
  query listRetailer(
    $page: Int
    $limit: Int
    $keyword: String
    $type: retailerType
    $region_id: [ID!]
    $province_id: [String!]
    $kabupaten_id: [String!]
  ) {
    listRetailer(
      listRetailerInput: {
        page: $page
        limit: $limit
        search: {
          keyword: $keyword
          country_id: []
          type: $type
          region_id: $region_id
          province_id: $province_id
          kabupaten_id: $kabupaten_id
          kecamatan_id: []
          kelurahan_id: []
        }
        order: { orderBy: CREATED_AT, sortBy: ASC }
      }
    ) {
      page
      limit
      total
      totalPage
      retailers {
        id
        name
        owner
        distributor
        retailer_type
        countrys {
          id
          country
        }
        regions {
          id
          region
        }
        provinces {
          id
          province
        }
        kabupatens {
          id
          kabupaten
        }
        kecamatans {
          id
          kecamatan
        }
        kelurahans {
          id
          kelurahan
        }
        zip
        alamat
        jalan
        longitude
        latitude
        mobile
      }
    }
  }
`;

export const M_ADD_RETAILER = gql`
  mutation createRetailer(
    $name: String!
    $owner: String
    $distributor: String
    $retailer_type: retailerType!
    $country_id: Int
    $region_id: Int
    $province_id: String
    $kabupaten_id: String
    $kecamatan_id: String
    $kelurahan_id: String
    $kodepos: Int
    $alamat: String
    $jalan: String
    $longitude: String
    $latitude: String
    $mobile: String
  ) {
    createRetailer(
      createRetailerInput: {
        name: $name
        owner: $owner
        distributor: $distributor
        retailer_type: $retailer_type
        country_id: $country_id
        region_id: $region_id
        province_id: $province_id
        kabupaten_id: $kabupaten_id
        kecamatan_id: $kecamatan_id
        kelurahan_id: $kelurahan_id
        kodepos: $kodepos
        alamat: $alamat
        jalan: $jalan
        longitude: $longitude
        latitude: $latitude
        mobile: $mobile
      }
    ) {
      id
    }
  }
`;

export const EDIT_RETAILER = gql`
  mutation UpdateRetailer(
    $id: ID!
    $name: String!
    $owner: String
    $distributor: String
    $retailer_type: retailerType!
    $country_id: Int
    $region_id: Int
    $province_id: String
    $kabupaten_id: String
    $kecamatan_id: String
    $kelurahan_id: String
    $kodepos: Int
    $alamat: String
    $jalan: String
    $longitude: String
    $latitude: String
    $mobile: String
  ) {
    updateRetailer(
      updateRetailerInput: {
        id: $id
        name: $name
        owner: $owner
        distributor: $distributor
        retailer_type: $retailer_type
        country_id: $country_id
        region_id: $region_id
        province_id: $province_id
        kabupaten_id: $kabupaten_id
        kecamatan_id: $kecamatan_id
        kelurahan_id: $kelurahan_id
        kodepos: $kodepos
        alamat: $alamat
        jalan: $jalan
        longitude: $longitude
        latitude: $latitude
        mobile: $mobile
      }
    ) {
      id
    }
  }
`;

export const M_DEL_RETAILER = gql`
  mutation deleteRetailer($id: ID!) {
    deleteRetailer(id: $id) {
      id
    }
  }
`;

// DISTRIBUTOR
export const Q_LIST_DISTRIBUTOR = gql`
  query listDistributor(
    $page: Int
    $limit: Int
    $keyword: String
    $region_id: [ID!]
    $province_id: [String!]
    $kabupaten_id: [String!]
  ) {
    listDistributor(
      listDistributorInput: {
        page: $page
        limit: $limit
        search: {
          keyword: $keyword
          country_id: []
          region_id: $region_id
          province_id: $province_id
          kabupaten_id: $kabupaten_id
          kecamatan_id: []
          kelurahan_id: []
        }
        order: { orderBy: CREATED_AT, sortBy: ASC }
      }
    ) {
      page
      total
      totalPage
      distributors {
        id
        name
        owner
        email
        zip
        alamat
        jalan
        longitude
        latitude
        mobile
        telephone
        isActive
        countrys {
          id
          country
        }
        regions {
          id
          region
        }
        provinces {
          id
          province
        }
        kabupatens {
          id
          kabupaten
        }
        kecamatans {
          id
          kecamatan
        }
        kelurahans {
          id
          kelurahan
        }
      }
    }
  }
`;

export const M_ADD_DISTRIBUTOR = gql`
  mutation createDistributor(
    $name: String!
    $owner: String
    $email: String
    $country_id: Int
    $region_id: Int
    $province_id: String
    $kabupaten_id: String
    $kecamatan_id: String
    $kelurahan_id: String
    $kodepos: Int
    $alamat: String
    $jalan: String
    $longitude: String
    $latitude: String
    $mobile: String
    $telephone: String
  ) {
    createDistributor(
      createDistributorInput: {
        name: $name
        owner: $owner
        email: $email
        country_id: $country_id
        region_id: $region_id
        province_id: $province_id
        kabupaten_id: $kabupaten_id
        kecamatan_id: $kecamatan_id
        kelurahan_id: $kelurahan_id
        kodepos: $kodepos
        alamat: $alamat
        jalan: $jalan
        longitude: $longitude
        latitude: $latitude
        mobile: $mobile
        telephone: $telephone
      }
    ) {
      id
    }
  }
`;

export const EDIT_DISTRIBUTOR = gql`
  mutation UpdateDistributor(
    $id: ID!
    $name: String!
    $owner: String
    $email: String
    $country_id: Int
    $region_id: Int
    $province_id: String
    $kabupaten_id: String
    $kecamatan_id: String
    $kelurahan_id: String
    $kodepos: Int
    $alamat: String
    $jalan: String
    $longitude: String
    $latitude: String
    $mobile: String
    $telephone: String
  ) {
    updateDistributor(
      updateDistributorInput: {
        id: $id
        name: $name
        owner: $owner
        email: $email
        country_id: $country_id
        region_id: $region_id
        province_id: $province_id
        kabupaten_id: $kabupaten_id
        kecamatan_id: $kecamatan_id
        kelurahan_id: $kelurahan_id
        kodepos: $kodepos
        alamat: $alamat
        jalan: $jalan
        longitude: $longitude
        latitude: $latitude
        mobile: $mobile
        telephone: $telephone
      }
    ) {
      id
    }
  }
`;

export const M_DEL_DISTRIBUTOR = gql`
  mutation deleteDistributor($id: ID!) {
    deleteDistributor(id: $id) {
      id
    }
  }
`;

export const LIST_KECAMATAN = gql`
  query listKecamatan(
    $page: Int
    $limit: Int
    $keyword: String
    $kabupaten_id: String
  ) {
    listKecamatan(
      listKecamatanInput: {
        page: 1
        limit: 25
        search: { keyword: $keyword, kabupaten_id: $kabupaten_id }
        order: { orderBy: KECAMATAN, sortBy: ASC }
      }
    ) {
      kecamatans {
        id
        kecamatan
      }
    }
  }
`;

export const LIST_KELURAHAN = gql`
  query listKelurahan(
    $page: Int
    $limit: Int
    $keyword: String
    $kecamatan_id: String
  ) {
    listKelurahan(
      listKelurahanInput: {
        page: 1
        limit: 25
        search: { keyword: $keyword, kecamatan_id: $kecamatan_id }
        order: { orderBy: KELURAHAN, sortBy: ASC }
      }
    ) {
      kelurahans {
        id
        kelurahan
      }
    }
  }
`;

export const PUBLISH_APPROVE = gql`
  mutation publishApprove($id: String!, $status: Boolean!) {
    publishCanApprove(input: { id: $id, status: $status }) {
      id
    }
  }
`;

export const PUBLISH_PROCESS = gql`
  mutation publishProcess($id: String!, $status: Boolean!) {
    publishCanProcess(input: { id: $id, status: $status }) {
      id
    }
  }
`;

export const PUBLISH_DELIVER = gql`
  mutation publishDeliver($id: String!, $status: Boolean!) {
    publishCanDeliver(input: { id: $id, status: $status }) {
      id
    }
  }
`;
